.frame {
    width:50%;
    margin:0px
}

.delete {
    margin-right:0;
    margin-left:auto;
}

.input-group-text {
    white-space: normal !important;
    text-align:left !important
}

.user-info {
    font-size: 7vmin
}

.row {
    position: relative
}

.form-check-input {
    position: absolute;
    top: 28%
}

.user-info {
    font-size: 3vw
}

#header {
    margin-top: 8vh;
}

@media screen and (min-width: 801px) {
    .task {
        font-size: 2vh
    }
}

@media screen and (max-width: 800px) {
    .task {
        font-size: 2.5vw
    }

    .X {
        font-size: 2.5vw
    }

    .frame {
        width:75%;
        margin:0px
    }

    .user-info {
        font-size: 5vw
    }
}
.ui.three.cards > .card {

    width: 100% !important;
    margin-left: 1em;
    margin-right: 1em;

}
body {

}
.ui.small.modal {

    width: 720px;
    margin: 0;
    height: 530px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;

}
.ui.small.modal {

    width: 95%;
    margin: 0;
    overflow-y: scroll;

}
#pin {
  color: green;
}
#unpin {

    color: rgb(33, 31, 109);

}
#created {

    background: #8080801a;
    padding: 10px;

}
#modals {
    overflow: hidden;
    border: 0px;
}
.pincode-input-container {
  background: #8080801a;
  padding: 10px;
}
.user.icon {

    font-size: 16px;

}
.calendar.alternate.outline.icon {

    font-size: 20px;

}
.pincode-input-container {

    background: #fff;
    padding: 10px;

}
.ui.page.modals.dimmer.transition.visible.active {

    background: #0202025c;

}
.pincode-input-text {

    border: 4px solid white !important;
    color: white;

}
.pincode-input-container {

    background: #8080801a;
    padding: 10px;

}
#created2 {

    background: #8080801a;
    padding: 10px;
    margin: 0px;

}
.ui.form {

    font-size: 1rem;
    width: 100% !important;

}
.ui.card .meta, .ui.cards > .card .meta {

    font-size: 1em;
    color: rgba(9, 9, 9, 0.98);

}
.ui.compact.menu {

    margin-top: 30px;

}
.ui.compact.menu {

    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0;
    vertical-align: middle;
    width: 100%;
    padding: 10px;

}
#header {

    margin-top: 0vh;

}
.ui.card .meta, .ui.cards > .card .meta {

    font-size: 1em;
    color: black !important;

}
.ui.image.label .detail {

    background: rgba(0, 0, 0, 0) !important;
    margin: -.5833em -.833em -.5833em .5em;
    padding: .5833em .833em;
    border-radius: 0 .28571429rem .28571429rem 0;
    color: black;
}
.ui.placeholder.segment {
  margin: 0px;
}
#created {

    background: #80808000;
    padding: 10px;

}
.ui.cards > .card {
    background: #8080801a !important;
}
.ui.card > .extra, .ui.cards > .card > .extra {
background-color: #00b5ad !important;
}
.ui.icon.button {

    border: 0px;

}
.ui.page.modals.dimmer.transition.visible.active {

    background: #0f0f0f26 !important;

}
.pincode-input-container {

    background: #80808000;
    padding: 10px;

}
.hQ2zbRipnDD-I93UK4cyA {

    margin: 10px;

}
#colors {

    border-color: #2185d0 !important;
    color: #fff !important;
    background-color: #00b5ad !important;

}
#editDelete {

    background: #32d04f00;
    color: white;

}
#editDelete2 {

    color: white;

}
.ui.compact.menu {

    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0;
    vertical-align: middle;
    background-color: rgb(33, 31, 109) !important;
    color: white !important;

}
.item {
  color: white !important;
  margin: 5px;
}
.ui.placeholder.segment {
background: linear-gradient(135deg, #be2bdb 0%,#ff6c2d 100%) !important;
}
h1 {

    color: white;

}
#toggles {
  background: transparent !important;
  color: white;
}
.ui.table {

    width: 100%;
    background: #fff;
    margin: 1em 0;
    border: 1px solid rgba(34,36,38,.15);
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: .28571429rem;
    text-align: left;
    color: rgba(0,0,0,.87);
    border-collapse: separate;
    border-spacing: 0;
    padding: 100px;
        padding-top: 100px;
    border: 0px !important;
    padding-top: 0px;

}
.ui.placeholder.segment {
border-radius: 0px !important;
}
#table {

    padding: 100px;

}
#created3 {

    padding: 10px;
        padding-left: 10px;
    padding-left: 0px;

}
#created4 {

    padding: 10px;
        padding-left: 10px;
    padding-left: 0px;
    text-transform: uppercase;

}
#created6 {
  background: black;
    padding: 10px;
        padding-left: 10px;
    padding-left: 0px;
    text-transform: uppercase;

}
.main {
  border-radius: 0px;
}
th {

    border-radius: 0px !important;

}
#foot {
  color: white;
}
.ui.buttons {

    width: 100%;

}
.ui.focus.input {

    width: 100%;

}
#createdStar {

    padding: 10px;
        padding-left: 10px;
    padding-left: 10px;
    padding-left: 0px;
    top: 5px;
    margin-right: 5px;

}
.detail {

    width: 200px;
    padding-bottom: 15px;
}
.ui.basic.center.aligned.segment {

    margin-top: 0px;

}
.footer-distributed{
	background: #666;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	width: 100%;
	text-align: left;
	font: bold 16px sans-serif;
	padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
	display: inline-block;
	vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left{
	width: 40%;
}

/* The company logo */

.footer-distributed h3{
	color:  #ffffff;
	font: normal 36px 'Open Sans', cursive;
	margin: 0;
}

.footer-distributed h3 span{
	color:  lightseagreen;
}

/* Footer links */

.footer-distributed .footer-links{
	color:  #ffffff;
	margin: 20px 0 12px;
	padding: 0;
}

.footer-distributed .footer-links a{
	display:inline-block;
	line-height: 1.8;
  font-weight:400;
	text-decoration: none;
	color:  inherit;
}

.footer-distributed .footer-company-name{
	color:  #222;
	font-size: 14px;
	font-weight: normal;
	margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center{
	width: 35%;
}

.footer-distributed .footer-center i{
	background-color:  #33383b;
	color: #ffffff;
	font-size: 25px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	margin: 10px 15px;
	vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope{
	font-size: 17px;
	line-height: 38px;
}

.footer-distributed .footer-center p{
	display: inline-block;
	color: #ffffff;
  font-weight:400;
	vertical-align: middle;
	margin:0;
}

.footer-distributed .footer-center p span{
	display:block;
	font-weight: normal;
	font-size:14px;
	line-height:2;
}

.footer-distributed .footer-center p a{
	color:  lightseagreen;
	text-decoration: none;;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight:300;
  font-size: 20px;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */

.footer-distributed .footer-right{
	width: 20%;
}

.footer-distributed .footer-company-about{
	line-height: 20px;
	color:  #92999f;
	font-size: 13px;
	font-weight: normal;
	margin: 0;
}

.footer-distributed .footer-company-about span{
	display: block;
	color:  #ffffff;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

.footer-distributed .footer-icons{
	margin-top: 25px;
}

.footer-distributed .footer-icons a{
	display: inline-block;
	width: 35px;
	height: 35px;
	cursor: pointer;
	background-color:  #33383b;
	border-radius: 2px;

	font-size: 20px;
	color: #ffffff;
	text-align: center;
	line-height: 35px;

	margin-right: 3px;
	margin-bottom: 5px;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {

	.footer-distributed{
		font: bold 14px sans-serif;
	}

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display: block;
		width: 100%;
		margin-bottom: 40px;
		text-align: center;
	}

	.footer-distributed .footer-center i{
		margin-left: 0;
	}

}
h1,
       h2,
       h3,
       h4,
       h5,
       h6 {}

       section {
           padding: 60px 0;
           min-height: 100vh;
       }

       a,
       a:hover,
       a:focus,
       a:active {
           text-decoration: none;
           outline: none;
       }

       a,
       a:active,
       a:focus {
           color: #6f6f6f;
           text-decoration: none;
           transition-timing-function: ease-in-out;
           -ms-transition-timing-function: ease-in-out;
           -moz-transition-timing-function: ease-in-out;
           -webkit-transition-timing-function: ease-in-out;
           -o-transition-timing-function: ease-in-out;
           transition-duration: .2s;
           -ms-transition-duration: .2s;
           -moz-transition-duration: .2s;
           -webkit-transition-duration: .2s;
           -o-transition-duration: .2s;
       }

       ul {
           margin: 0;
           padding: 0;
           list-style: none;
       }
       img {
   max-width: 100%;
   height: auto;
}.footer-section {
 background-color: #233243;
 position: relative;
 overflow: hidden;
 z-index: 9;
}
.footer-section:before {
 content: '';
 position: absolute;
 top: -146%;
 left: -18%;
 width: 44%;
 height: 257%;
 transform: rotate(54deg);
 background-color: rgb(31, 47, 64);
 -webkit-transform: rotate(54deg);
 -moz-transform: rotate(54deg);
 -ms-transform: rotate(54deg);
 -o-transform: rotate(54deg);
 z-index: -10;
}
.footer-section:after {
 position: absolute;
 content: '';
 background-color: rgb(31, 47, 64);
 top: -24%;
 right: 4%;
 width: 26%;
 height: 264%;
 transform: rotate(44deg);
 -webkit-transform: rotate(44deg);
 -moz-transform: rotate(44deg);
 -ms-transform: rotate(44deg);
 -o-transform: rotate(44deg);
 z-index: -10;
}
.footer-top {
 padding-top: 96px;
 padding-bottom: 50px;
}
.footer-top p,
.company-footer-contact-list li {
 color: #ffffff;
}
.company-footer-contact-list {
 margin-top: 10px;
}
.company-footer-contact-list li {
 display: flex;
 display: -webkit-flex;
 align-items: center;
}
.company-footer-contact-list li+li {
 margin-top: 5px;
}
.company-footer-contact-list li i {
 margin-right: 10px;
 font-size: 20px;
 display: inline-block;
}

.footer-top .site-logo {
   margin-bottom: 25px;
   display: block;
   max-width: 170px;
}
.widget-title {
 text-transform: capitalize;
}
.footer-top .widget-title {
 color: #ffffff;
 margin-bottom: 40px;
}
.courses-link-list li+li {
 margin-top: 10px;
}
.courses-link-list li a {
 color: #ffffff;
 text-transform: capitalize;
 font-family: var(--para-font);
 font-weight: 400;
}
.courses-link-list li a:hover {
 color: #ffb606;
}
.courses-link-list li i {
 margin-right: 5px;
}
.footer-top .small-post-title a {
 font-family: var(--para-font);
 color: #ffffff;
 font-weight: 400;
}
.small-post-item .post-date {
 color: #ffb606;
 margin-bottom: 3px;
 font-family: var(--para-font);
 font-weight: 400;
}
.small-post-list li+li {
 margin-top: 30px;
}
.news-letter-form {
 margin-top: 15px;
}
.news-letter-form input {
 width: 100%;
 padding: 12px 25px;
 border-radius: 5px;
 -webkit-border-radius: 5px;
 -moz-border-radius: 5px;
 -ms-border-radius: 5px;
 -o-border-radius: 5px;
 border: none;
}
.news-letter-form input[type="submit"] {
 width: auto;
 border: none;
 background-color: #ffb606;
 padding: 9px 30px;
 border-radius: 5px;
 -webkit-border-radius: 5px;
 -moz-border-radius: 5px;
 -ms-border-radius: 5px;
 -o-border-radius: 5px;
 color: #ffffff;
 margin-top: 10px;
}
.footer-bottom {
 padding: 13px 0;
 border-top: 1px solid rgba(255, 255, 255, 0.149);
}
.copy-right-text {
 color: #ffffff;
}
.copy-right-text a {
 color: #ffb606;
}
.terms-privacy li+li {
 margin-left: 30px;
}
.terms-privacy li a {
 color: #ffffff;
 position: relative;
}
.terms-privacy li a:after {
 position: absolute;
 content: '-';
 color: #ffffff;
 display: inline-block;
 top: 0;
 right: -18px;
}
.paypal-logo {
  font-family: Verdana, Tahoma;
  font-weight: bold;
  font-size: 26px;
}
.paypal-logo i:first-child {
  color: #253b80;
}
.paypal-logo i:last-child {
  color: #179bd7;
}
.paypal-button {
  padding: 15px 30px;
  border: 1px solid #f93;
  border-radius: 5px;
  background-image: linear-gradient(#fff0a8, #f9b421);
  margin: 0 auto;
  display: block;
  min-width: 138px;
  position: relative;
}
.paypal-button-title {
  font-size: 14px;
  color: #505050;
  vertical-align: baseline;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6);
}
.paypal-button .paypal-logo {
  display: inline-block;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6);
  font-size: 20px;
}
.terms-privacy li+li a:after {
 display: none;
}
#public {
  color: white;
}
.user.outline.icon {

    font-size: 18px;

}
.header {

    background: #1b1960 !important;
    color: white !important;
    text-transform: uppercase;
    font-size: 15px !important;
    border-radius: 0px !important;

}
.ui.icon.header {

    background: transparent !important;

}
#vault {
  background: transparent !important;
}
#pro {

    text-decoration: underline;

}
#low {
  border-right: 1px solid white;
}
#strong {
  border-left: 1px solid white;
}
.ui.page.modals.dimmer.transition.visible.active {

    background: rgba(15, 15, 15, 0.87) !important;

}
#new {
  color: black !important;
}
#widthTable {
  width: 80% !important;
}
#sidebar {


}
.description {

    padding-left: 10% !important;
    padding-right: 10% !important;

}
.ui.item.simple.dropdown {

    color: black !important;

}
.container {

    max-width: 3000px !important;

}
.ui.celled.definition.compact.table {

    padding: 0px;

}


.nav-pills > li > a {
  border-radius: 0;
}

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  overflow: hidden;
}

#wrapper.toggled {
  padding-left: 250px;
  overflow: hidden;
}

#sidebar-wrapper {
  z-index: 1000;
  position: absolute;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #000;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  position: absolute;
  padding: 15px;
  width: 100%;
  overflow-x: hidden;
}

.xyz {
  min-width: 360px;
}

#wrapper.toggled #page-content-wrapper {
  position: relative;
  margin-right: 0px;
}

.fixed-brand {
  width: auto;
}
/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 2px;
}

.sidebar-nav li {
  text-indent: 15px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #999999;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  border-left: red 2px solid;
}
#li2 {

    text-align: center !important;

}
.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}
.fa-phone::before {

    content: "" !important;

}
.no-margin {
  margin: 0;
}

@media (min-width: 768px) {
  #wrapper {
     padding-left: 250px;
  }
  .fixed-brand {
     width: 250px;
  }
  #wrapper.toggled {
     padding-left: 0;
  }
  #sidebar-wrapper {
     width: 250px;
  }
  #wrapper.toggled #sidebar-wrapper {
     width: 250px;
  }
  #wrapper.toggled-2 #sidebar-wrapper {
     width: 50px;
  }
  #wrapper.toggled-2 #sidebar-wrapper:hover {
     width: 250px;
  }
  #page-content-wrapper {
     padding: 20px;
     position: relative;
     -webkit-transition: all 0.5s ease;
     -moz-transition: all 0.5s ease;
     -o-transition: all 0.5s ease;
     transition: all 0.5s ease;
  }
  #sidebar-wrapper {

    background: #22224800;

  }
  #wrapper.toggled #page-content-wrapper {
     position: relative;
     margin-right: 0;
     padding-left: 250px;
  }
  #wrapper.toggled-2 #page-content-wrapper {
     position: relative;
     margin-right: 0;
     margin-left: -200px;
     -webkit-transition: all 0.5s ease;
     -moz-transition: all 0.5s ease;
     -o-transition: all 0.5s ease;
     transition: all 0.5s ease;
     width: auto;
  }
}
#sidebar-wrapper {

    background: #22224800 !important;

}
.navbar.navbar-default.no-margin {
background: #222248 !important;
}
#showTable {

    font-size: 12px;
    padding: 0px;

}
#sidebar-wrapper {

      background: #E8EBF4 !important;

}
.site-wrapper {

    background: #E8EBF4 !important;

}
#showTable {

    margin: 0px;

}
.ui.teal.icon.left.labeled.button {

  margin: 0 auto;
  display: block;

}
#filterInput {

    text-align: center;

}
#created3 {

    font-size: 12px;
    font-weight: bold;
    color: black;
}
#toggle {

    background: #00000014;
    border: 0px;
    padding: 5px;
    width: 30px;

}
#toggle:hover {

    background: white;
    border: 0px;
    padding: 5px;
    width: 30px;

}
#filterInput {

    border: none;
}
#img2 {

    width: 195px !important;
    margin-top: 10px;

}
#editDelete2 {

    background: #7b7b7b;
    color: white;
    border: 1px dotted;

}
#img4 {

    width: 150px;

}
.ui.teal.icon.left.labeled.button {

    width: 90%;

}
#empty {
  text-align: center;
  color: black;
}
#empty2 {
  display: block;
 margin-left: auto;
 margin-right: auto;

}
.ui.compact.menu {

    background: #0b10289e !important;

}
h1 {

    font-weight: bold !important;
    padding-top: 10px !important;

}
.ui.small.modal.transition.visible.active .header {

    color: black !important;
    background-color: rgb(255, 255, 255) !important;
}
.ui.small.modal.transition.visible.active .actions {

    color: black !important;
    background-color: rgb(232, 235, 244) !important;
}
body {
    background: #E8EBF4 !important;

}
.ui.grey.inverted.button {

    color: black;

}
#created8 {

    padding: 0px;
    margin-top: 10px !important;

}
#Gener {

    height: 35px !important;

}
#forms {

    padding: 0px;

}
#boltMe {
  width: 25%;
  line-height: 30px;
}
#notice {

    padding-top: 25px;
    line-height: 15px;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 1px;

}
.slider {
  width: 100%;
  height: 15px;
  border-radius: 0px;

  outline: none;
  opacity: 0.9;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 0%;

  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 0%;

  cursor: pointer;
}
#basicCollapse {

    margin-top: 0px;
    border-right: 5px solid #80808014;

}
#generatorsModal {

    padding: 15px;
    background-color: white;
    margin-top: 10px;
    border: 0px solid #0000002b !important;

}
.description {
  padding: 0px !important;
}
#Gener2 {
  width: 100%;
  background-color: rgb(0, 181, 173);
  color: white;
}
#Gener2:hover {
  width: 100%;
  background-color: rgba(0, 181, 173, 0.61);


}
#notice {

    text-align: center;

}
.s3231s {

    background: #00000012 !important;

}
.card-header.text-center.font-weight-bold.text-uppercase.py-4 {

    color: black !important;
    border: 1px solid gray;
    font-size: 14px !important;

}
.image.content {

    background-color: rgb(232, 235, 244) !important;

}
#white {

    background: #f9f9f9;
    padding: 10px;
        padding-bottom: 10px;
    padding-bottom: 15px !important;
    margin: 0px;
    border-left: 2px solid;
    border-right: 2px solid;
    border-top: 2px solid;
}
#white2 {

    background: #f9f9f9;
    padding: 10px;
    padding-top: 0px;
    border-left: 2px solid;

border-right: 2px solid;

border-bottom: 2px solid;
}
#myRange {
  background-color: rgb(13, 113, 187);
}
.text {

    color: black;

}

.card-header.text-center.font-weight-bold.text-uppercase.py-4 {

    padding: 5px !important;
    margin: 0px !important;
    border: 0px;
    font-size: 14px !important;

}
.ui.small.modal.transition.visible.active {

    background: rgb(232, 235, 244) !important;

}
#created7 {

    padding-top: 10px !important;

}
#modals {

    background: transparent !important;
    padding-top: 10%;

}
#extras {

    width: 550px !important;

}
#Gener {

    margin-top: 15px;

}
#Gener3 {

    padding: 10px;
    background: #0000;
    float: right !important;

}
#s {
  color: black;
  text-transform: uppercase !important;
  font-size: 12px !important;
}
.user.circle.icon {

    color: white;

}
.text {

    color: white;

}
.text2 {
  color: black;
}
.ui.stackable.menu {

    width: 100% !important;
    background-color: rgba(11, 16, 40, 0.62);
    margin: 0;

}
#testdrop {

    color: white !important;

}
#lower {
  text-transform: none;
}
.inbox.icon {

    font-size: 25px !important;

}
.ui.icon.message {

    font-size: 20px !important;
    padding: 5px;

}
#logs {

    color: black;
    width: 100%;
    font-size: 13px;

}
.ui.block.header {

    border: 0px !important;

}
#logsContent {

    font-size: 12px !important;
    background-color: rgb(255, 255, 255) !important;
    padding-top: 0px;

}
.ui.icon.message {

    padding: 5px;
    font-size: 18px !important;

}
.ui.positive.top.attached.button {

    width: 100%;

}
.spinner-border {

    font-size: 12px !important;
    height: 18px !important;
    width: 17px !important;

}
#IsoMode {

    height: 650px !important;
    border: 0px !important;
    color: black;
    font-size: 15px;
   background-color: rgb(255, 255, 255) !important;
    width: 100%;


}
.remove.icon {

    height: 18px !important;

}
.send.icon {

    height: 18px !important;

}
.dropdown.icon {

    color: white !important;

}
.ui.block.header {

    width: 100% !important;

}
#editDelete2:hover {

    background: #8080801c !important;

}
#myIp {

    border: 0px;
    background: #00000005;

}
#IsoMode {
  height: 520px;
  border: 0px !important;
  margin: 0px !important;
  padding: 0px;
}
#noBack
{
  background: transparent !important;
}
#no2 {

    color: black !important;
    opacity: 1;

}
#headIso {
  background-color: rgba(11,16,40,.62) !important;
  background-attachment: scroll !important;
background-clip: border-box !important;;
background-color: rgba(238, 238, 238, 0.98) !important;

}
#contentIso {
background: linear-gradient(135deg,#372bdbd1,#3750ce4a) !important;
  opacity: 0.95;
}
#tops {
  top: -3px;
}
#tops3 {
  top: 0px;
}

#tops2 {
  top: 4px;
}
#righs {

    float: right;

}
.ui.twitter.button {

    background: #000000ba !important;

}
.ui.twitter.button:hover {

    background-color: red !important

}
#conf {

    background: #000000ba !important;

}
#conf:hover {

    background-color: green !important

}

.ui.google.plus.button {

    background: #000000ba !important;

}
.ui.google.plus.button:hover {

    background: grey !important;

}
#IsoMode {

    height: 520px;
    border: 0px !important;
    background: rgba(255, 255, 255, 0.98) 0%;
    color: black;

}
#modals7 {
  background: transparent !important;
  overflow: hidden;
}
#modals6 {

    background: transparent !important;
    padding-top: 10%;
    overflow: hidden;
    border: 0px;
}
#no2 {

    background-color: rgba(11, 16, 40, 0.03) !important;
    border-bottom: 1px solid #00000017 !important;

}
.ui.vk.button {

    width: 100%;

}
#clearMe {

    background: transparent;
    font-size: 14px;

}
#clearMe:hover {

    background: rgba(0, 0, 0, 0.25);

}
#closeMe {
  float: left;
  margin: 0px;
  background: #ae313100;
    font-size: 14px;
}
#closeMe:hover {
  margin: 0px;
  background: rgba(0, 0, 0, 0.25);
}
.as {

    border-radius: 0px !important;

}
.close.icon {

    height: 17px;

}
.random.icon {

    height: 15px;

}
#asItem {

    color: black !important;
    font-weight: bold;
    border-radius: 0px;
    border-radius: 0px !important;
    margin: 0px !important;
}
.ui.small.modal.transition.visible.active {

    height: 90% !important;
    max-height: 2000px;
    margin: 0px !important;
    width: 100%;
    border: 5px solid #ffffff21;

}
.key.icon {
  color: black !important;
}
#sec {
  color: black !important;
  font-weight: bold;
}
.code.icon {

    color: black;
    font-size: 15px;

}
.book.icon {

    color: black;
    font-size: 15px;

}
#modals2 {
background-color: rgb(255, 255, 255) !important;
border: 5px solid #f0eded !important;
}
#logsContent {

    font-size: 12px !important;
    background-color: rgb(255, 255, 255) !important;
    padding: 0px !important;

}
#full {
  width: 100%;
  padding: 0px;
  font-weight: bold;
}
#as {
     border-radius: 0px !important;
     margin: 0px;
}
#confirmation {
  background-color: rgb(249, 250, 251);
line-height: 18.2px;
padding-bottom: 12.0667px;
padding-left: 10.2167px;
padding-right: 10.2167px;
padding-top: 12.0667px;
font-weight: bold;
}
#textContainers {
  padding: 10px;
}
#dividerCss {
  margin-top: 0px;
}
@media only screen and (max-width: 850px) {
  .ui.stackable.menu {

      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      padding-right: 45px;
      margin: 15px;

  }
  .form-control {

    width: 100% !important;

  }
  #otherInput {
    width: 100% !important;
  }
  #password {
    width: 100% !important;
  }
  #randomText {
    width: 100% !important;
  }
  .collapsing {

    height: 30px !important;

  }
  .ui.buttons {

    display: grid !important;

  }

  #as{
   display: revert !important;
   border-radius: 0px !important;
   margin: 0px !important;
  }
  #mobileMenu {

    display: revert !important;

  }
  .collapsing {

    height: 30px;

  }
  .ui.google.plus.button {

      margin-top: 15px;
  }
  .ui.google.plus.button {
      margin-top: 15px;
  }
  .ui.twitter.button {
    margin-top: 15px !important;
  }
  #extras {

    width: 300px !important;

  }
  .form-control {

    width: 50% !important;

  }
  th {

    display: none;

  }
  #wrapper {
    padding: 0px !important;

}
#sidebar-wrapper {

    display: none;

}
#toggle {

    display: none;

}
}
