.navbar {
  background-color:  rgb(33, 31, 109) !important;
}

.avatar {
  border-radius:3px
}
#login{
  color: white;
}
#logout {

    background-color: #00b5ad !important;
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    color: white;
}
.navbar.navbar-expand-md.navbar-dark.bg-blue.fixed-top {

    background: #E8EBF4 !important;
    color: black !important;
    color: black !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    font-size: 14px !important;

}
.navbar-dark .navbar-brand {

  background: #E8EBF4 !important;
  color: black !important;
  color: black !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 14px !important;

}
.navbar {

    background: #E8EBF4 !important;
    color: black !important;
    color: black !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
    font-size: 14px !important;

}
#dashboard {

    width: 100%;

}
.trash.alternate.icon {

    font-size: 12px;

}
