
.intro {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 40px;
}

.ui grid {

    background: #27297deb;

}
h1 {
  font-size: 25px !important;
  font-weight: bold !important;
}
h3 {
  font-size: 18px !important;
  font-weight: bold !important;
  color: white !important;
}
#subtitle {
  color: white;
}
#dont {

    text-align: left;
    margin: 5px;

}
.discord.icon {

    font-size: 30px !important;
    color: white !important;

}
.rocketchat.icon {

    font-size: 30px !important;
    color: white !important;

}
.like.icon {

    font-size: 20px !important;
    color: red !important;
    padding-left: 5px !important;
}
.linkify.icon {

    font-size: 20px !important;
}
.item {

    text-align: left;

}
#personal {
  color: orange;
    font-weight: bold;
}
#trans {
  color: #a9e8ff;
  font-weight: bold;
}
#signin-button {

    background: #ff005ec7 !important;

}
.header.item {

    background: #27297deb !important;

}
.ui.inverted.header {

    background: rgba(39, 41, 125, 0.92) !important;

}
.ui.small.modal.transition.visible.active {

    height: 540px !important;

}
.description {

    width: 80% !important;

}
.ui.small.modal.transition.visible.active {

    max-width: 1500px;

}
.ui.right.negative.button {

    float: right;
    margin: 0px;

}
.ui.small.modal.transition.visible.active {

    height: 540px !important;
    height: 80% !important;
    max-height: 2000px;

}
#created7 {

    padding-top: 5px;

}
.ui.labeled.input > .label:not(.corner) {

    padding-top: .78571429em;
    padding-bottom: 0px !important;

}
.actions {

    background-color: rgb(27, 25, 96) !important;

}
.table.icon {

    color: #fff;

}
.ui.button {

    border-radius: 0px !important;

}
#table {
  padding-top: 0px;
  padding-right: 10px;
      font-size: 13px;
}
#showTable {

    font-size: 13px;

}
.ui.card {

    font-size: 12px !important;

}
body {

  background: rgba(39, 41, 125, 0.92);
  z-index: -1000;

}
ui.text.container {

    margin-top: 0em !important;

}
.ui.item.simple.dropdown {

    padding: 5px;

}
#witdthMe {
  width: 100%;
}
.ui.labeled.input {

    width: 100%;

}
#generatorsModal {
padding: 15px;
background-color: white;
margin-top: 10px;
border: 0px solid #0000002b !important;
}
#forGenerator {
  margin: 0 !important;
}
#cloesWindow {
margin-bottom: 15px;
position: relative;
width: 100%;
background: #0eff006e;


}
#editDelete2 {

    background: transparent;
    color: black;

}
#created3 {

    padding-bottom: 0px;

}
.image.content {

    background-color: rgb(255, 255, 255) !important;

}
.card-header.text-center.font-weight-bold.text-uppercase.py-6 {

    background: transparent;
    border: 0px;

}
#notice {

    background: white !important;

}
